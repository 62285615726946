<template>
  <div>
    <RequestsTable
      :requests-loading="loading"
      :requests="requests"
      :page-limit="PAGE_LIMIT"
      :current-page="currentPage"
      :businesses="businesses"
      @update-request="handleUpdateRequest"
    />
    <div class="d-flex justify-content-end my-3">
      <el-pagination
        small
        layout="prev, pager, next, jumper"
        background
        :current-page.sync="currentPage"
        :page-size="PAGE_LIMIT"
        :page-count="Math.ceil(totalCount / PAGE_LIMIT)"
        :total="totalCount"
      />
    </div>
  </div>
</template>

<script>
import { ref, computed, watch, getCurrentInstance } from 'vue';

import { RequestsTable } from '../components';
import { useRequests } from '../compositions/useRequests';

const PAGE_LIMIT = 25;

export default {
  name: 'ClosedRequests',
  components: {
    RequestsTable,
  },
  props: {
    businesses: { type: Array, default: () => [] },
  },
  setup() {
    const root = getCurrentInstance().proxy;
    const currentPage = ref(1);

    const targetBusinessIdFilter = computed({
      get: () => root.$route.query.targetBusinessId,
    });
    const sourceBusinessIdFilter = computed({
      get: () => root.$route.query.sourceBusinessId,
    });
    const sortBy = computed({
      get: () => root.$route.query.sortBy,
    });

    const orderBy = computed({
      get: () => root.$route.query.orderBy,
    });

    const variables = computed(() => ({
      limit: PAGE_LIMIT,
      offset: (currentPage.value - 1) * PAGE_LIMIT,
      targetBusinessId: targetBusinessIdFilter.value,
      sourceBusinessId: sourceBusinessIdFilter.value,
      closed: true,
      sort:
        sortBy.value && orderBy.value
          ? {
              sortBy: sortBy.value,
              orderBy: orderBy.value === -1 ? 'asc' : 'desc',
            }
          : {
              sortBy: 'closedAt',
              orderBy: 'desc',
            },
    }));

    watch([targetBusinessIdFilter, sourceBusinessIdFilter], () => (currentPage.value = 1), { immediate: true });

    const { requests, totalCount, loading, updateRequest } = useRequests(variables);

    return {
      PAGE_LIMIT,
      requests,
      totalCount,
      loading,
      currentPage,
      updateRequest,
    };
  },
  methods: {
    handleUpdateRequest(updateData) {
      this.updateRequest(updateData);
    },
  },
};
</script>
