<template>
  <div>
    <RequestsTable
      :requests-loading="loading"
      :requests="requests"
      :page-limit="PAGE_LIMIT"
      :current-page="currentPage"
      :businesses="businesses"
      :with-response-action="true"
      :with-visibility-eye="true"
      @update-request="handleUpdateRequest"
      @show-response-request-modal="showResponseRequestModal"
    />
    <div class="d-flex justify-content-end my-3">
      <el-pagination
        small
        layout="prev, pager, next, jumper"
        background
        :current-page.sync="currentPage"
        :page-size="PAGE_LIMIT"
        :page-count="Math.ceil(totalCount / PAGE_LIMIT)"
        :total="totalCount"
      />
    </div>
    <RequestResponseModal
      v-if="requests[requestToResponseIndex]"
      :request="requests[requestToResponseIndex]"
      @close="requestToResponseIndex = null"
      @create-request-response="handleCreateRequestResponse"
    />
  </div>
</template>

<script>
import { ref, computed, watch, getCurrentInstance } from 'vue';

import { RequestsTable, RequestResponseModal } from '../components';
import { useRequests, useCreateResponse } from '../compositions/useRequests';

const PAGE_LIMIT = 25;

export default {
  name: 'OpenRequests',
  components: {
    RequestsTable,
    RequestResponseModal,
  },
  props: {
    businesses: { type: Array, default: () => [] },
  },
  setup() {
    const requestToResponseIndex = ref(null);
    const root = getCurrentInstance().proxy;
    const currentPage = ref(1);

    const targetBusinessIdFilter = computed({
      get: () => root.$route.query.targetBusinessId,
    });
    const sourceBusinessIdFilter = computed({
      get: () => root.$route.query.sourceBusinessId,
    });

    const sortBy = computed({
      get: () => root.$route.query.sortBy,
    });

    const orderBy = computed({
      get: () => root.$route.query.orderBy,
    });

    const variables = computed(() => ({
      limit: PAGE_LIMIT,
      offset: (currentPage.value - 1) * PAGE_LIMIT,
      targetBusinessId: targetBusinessIdFilter.value,
      sourceBusinessId: sourceBusinessIdFilter.value,
      closed: false,
      isAwaitingResponse: true,
      sort:
        sortBy.value && orderBy.value
          ? {
              sortBy: sortBy.value,
              orderBy: orderBy.value === -1 ? 'asc' : 'desc',
            }
          : {
              sortBy: 'activeAt',
              orderBy: 'asc',
            },
    }));

    watch([targetBusinessIdFilter, sourceBusinessIdFilter], () => (currentPage.value = 1), { immediate: true });

    const {
      requests,
      totalCount,
      loading: requestLoading,
      updateRequest,
      refetch: refetchRequests,
    } = useRequests(variables);
    const { mutate: createResponse, onDone: onResponseCreated, onError: onResponseCreateError } = useCreateResponse();

    onResponseCreated(() => {
      refetchRequests();
      root.$message.success(root.$t('requests.serviceProviderResponseModal.submittedForReview'));
    });
    onResponseCreateError(() => {
      root.$message.error(root.$t('commons.messages.action.error'));
    });

    const showResponseRequestModal = (requestIndex) => (requestToResponseIndex.value = requestIndex);

    return {
      PAGE_LIMIT,
      requests,
      totalCount,
      loading: requestLoading,
      currentPage,
      updateRequest,
      createResponse,
      showResponseRequestModal,
      requestToResponseIndex,
    };
  },
  methods: {
    handleUpdateRequest(updateData) {
      this.updateRequest(updateData);
    },
    async handleCreateRequestResponse(responseText) {
      const respondToRequest = this.requests[this.requestToResponseIndex];
      this.requestToResponseIndex = null;
      await this.createResponse({
        requestId: respondToRequest.id,
        createParams: { text: responseText },
      });
    },
  },
};
</script>
